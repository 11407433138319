import Vue, { VueConstructor } from "vue";
/* @todo see if need fa
/// FA
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component('fa', FontAwesomeIcon);


// Change icons into SVGs
dom.watch();
*/

export default Vue;

export function initializeVue(VueInstance: VueConstructor<Vue>) {
  // VueInstance.config.productionTip = false;
  const app: Vue = new VueInstance({
    data: () => {
      const meta = document.head.querySelector('meta[name="vue-data"]');
      if (meta) {
        const data = JSON.parse(meta.getAttribute("content") + "");
        meta.remove();
        return data;
      }
      return {};
    }
  });
  app.$mount('#app');
}
