






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BoardItem extends Vue {
  flipped = false;
}
