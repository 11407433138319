






import { Component, Prop, Vue } from "vue-property-decorator";
import { easing, keyframes, styler }  from 'popmotion';

@Component
export default class Template extends Vue {

  // new docs
  // try to use native js css for this effect
  // https://codepen.io/desandro/pen/LmWoWe

  @Prop() backgroundImage!: string;

  visible = false;
  current = 0;

  created(){
    this.$parent.$on('componentScrolled', (prev: number, pos: number) => this.handleScroll(prev, pos));
  }

  mounted(){
    let element = this.$refs.scrollable as HTMLElement;
    if(this.backgroundImage) element.style.background = `url(${this.backgroundImage})`;
    /*
    if(element && element.parentElement) {
      element.style.width = (element.parentElement.clientWidth - 100) + "px";
    }
    */
    var observer = new IntersectionObserver(entries => {
      this.visible = entries[0].isIntersecting;
    }, { threshold: [0] });
    observer.observe(element);
  }

  handleScroll(prev:number, pos:number) {
    if(this.visible && prev != pos){
      let operation = prev < pos ? (this.current - 1) : (this.current + 1);
      if(operation > -70 && operation < 70) {
        const divStyler = styler(this.$refs.scrollable as Element);
        keyframes({
          values: [
            { x: 0, y: 0, rotateY: 0, rotateX: this.current },
            { x: 0, y: 0, rotateY: 0, rotateX: operation }
          ],
          duration: 100,
          easings: [easing.easeInOut, easing.easeInOut, easing.easeInOut, easing.easeInOut],
        }).start(divStyler.set);
        this.current = operation;
      }
    }
  }
}
