import Vue from "../init";
import BoardItem from '@/components/BoardItem.vue';
import { Component } from "vue-property-decorator";

@Component({
  components : {
    'board-item' : BoardItem,
  }
})
export default class BaseController extends Vue {
  public contactEmail(){
    const dir = 'info';
    const at =  '@';
    const domain = "xmlteam.com"
    return `${dir}${at}${domain}`;
  }
}
