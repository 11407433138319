import Vue, { initializeVue } from "@/init";
import { Component } from "vue-property-decorator";
import BaseController from '@/controllers/base.controller';
import Scrollable from '@/components/Scrollable.vue';


@Component({
  components: {
    'scrollable' : Scrollable
  }
})
export default class HomeController extends BaseController {

  scroll = {
    last_known_scroll_position : 0,
    ticking : false,
  };

  mounted(){
    let element = this.$refs.contentSection as HTMLElement;
    if(element) {
      element.style.marginRight = '-' + (element.offsetWidth - element.clientWidth) + "px";
    }
  }

  updateScroll(e:EventTarget){
    if(e.target){
      let prev = this.scroll.last_known_scroll_position;
      this.scroll.last_known_scroll_position = e.target.scrollTop;

      if (!this.scroll.ticking) {
        window.requestAnimationFrame(() => {
          //this.$emit('componentScrolled', prev, this.scroll.last_known_scroll_position);
          this.scroll.ticking = false;
        });
      }
      this.scroll.ticking = true;
    }
  }
}

initializeVue(HomeController);
